import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

import './main.css';


export default function IndexPage() {
    return <Layout>
        <SEO title="Questfeed" />

        <div className="topHalf">
            <div>
                <h1>Questfeed</h1>
            </div>
        </div>
        <div className="bottomHalf">
            <div>Be right back</div>
        </div>
    </Layout>;
}
